<template>
    <div v-if="product && Object.keys(product).length > 0" class="container">
        <section
            id="section-history"
            data-aos="fade-in"
            data-aos-duration="1000"
        >
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 equalHW padding0" v-if="product.image">
                        <img
                            class="product-image img img-fluid"
                            :src="product.image"
                        />
                        <!--
                        <div
                            class="history-image product-image"
                            v-bind:style="
                                'background: url(' +
                                    product.image +
                                    ') no-repeat top center #191b1b;background-size:cover;'
                            "
                        ></div>-->
                    </div>
                    <div class="col-lg-6 equalHW padding0" v-else></div>
                    <div class="col-lg-6 equalHW padding0">
                        <div class="section specialities">
                            <div class="section history">
                                <div
                                    class="history-content alignVCenter"
                                    style="padding-top: 10px !important;"
                                >
                                    <div class="container-col">
                                        <!--<h4 class="small medium right light">Discover your taste</h4>-->
                                        <h4 class="white title">
                                            {{ product.name }}
                                        </h4>
                                        <span class="price-formatted">
                                            {{ calculatedPrice }} Ft
                                        </span>
                                        <div class="voffset-30"></div>
                                        <p v-html="product.description"></p>
                                        <!---->
                                        <div
                                            class="voffset-30"
                                            v-if="
                                                product &&
                                                    product.packages &&
                                                    product.packages.length > 0
                                            "
                                        ></div>
                                        <h5
                                            class="white title"
                                            v-if="
                                                product &&
                                                    product.packages &&
                                                    product.packages.length > 0
                                            "
                                        >
                                            {{ product.combinated_offer_text }}
                                        </h5>
                                        <div class="booking-form">
                                            <template
                                                v-for="(item,
                                                index) in product.packages"
                                            >
                                                <div
                                                    v-bind:key="
                                                        'package-divider-' +
                                                            index
                                                    "
                                                    class="voffset-30"
                                                ></div>
                                                <label
                                                    v-bind:key="
                                                        'package-label-' + index
                                                    "
                                                    >{{ item.name }}</label
                                                >
                                                <select
                                                    class="custom-select"
                                                    v-bind:key="
                                                        'package-' + index
                                                    "
                                                    v-model="
                                                        model.packages[item.id]
                                                    "
                                                >
                                                    <option
                                                        :value="null"
                                                        selected
                                                        >{{ item.name }}</option
                                                    >
                                                    <template
                                                        v-for="(tItem,
                                                        tIndex) in item.products"
                                                    >
                                                        <option
                                                            v-bind:key="
                                                                't-item-' +
                                                                    index +
                                                                    '-' +
                                                                    tIndex
                                                            "
                                                            :value="tItem.id"
                                                        >
                                                            {{ tItem.name }}
                                                            &nbsp;(+{{
                                                                tItem.price
                                                            }})</option
                                                        >
                                                    </template>
                                                </select>
                                            </template>
                                        </div>
                                        <div
                                            class="voffset-30"
                                            v-if="
                                                product &&
                                                    product.additionals &&
                                                    product.additionals.length >
                                                        0
                                            "
                                        ></div>
                                        <h5
                                            class="white title"
                                            v-if="
                                                product &&
                                                    product.additionals &&
                                                    product.additionals.length >
                                                        0
                                            "
                                        >
                                            {{ product.extra_offer_text }}
                                        </h5>
                                        <div
                                            v-for="(item,
                                            index) in product.additionals"
                                            v-bind:key="'additional-' + index"
                                            class="custom-control custom-checkbox"
                                        >
                                            <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                v-model="model.extras[item.id]"
                                                :id="'additional-' + index"
                                                :value="item.id"
                                            />
                                            <label
                                                class="custom-control-label white"
                                                :for="'additional-' + index"
                                                >{{ item.name }} (+{{
                                                    item.price
                                                }}
                                                )</label
                                            >
                                        </div>
                                        <div class="voffset-30"></div>
                                        <div class="booking-form">
                                            <div class="form-group">
                                                <input
                                                    type="number"
                                                    v-model="model.quantity"
                                                    class="form-control"
                                                    id="exampleInputPassword1"
                                                    placeholder="Mennyiség"
                                                />
                                            </div>
                                        </div>
                                        <div class="voffset-30"></div>
                                        <div class="booking-form">
                                            <div class="form-group">
                                                <label
                                                    for="exampleFormControlTextarea2"
                                                    >Speciális kérés</label
                                                >
                                                <textarea
                                                    class="form-control"
                                                    v-model="model.comment"
                                                    id="exampleFormControlTextarea2"
                                                    rows="2"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div class="voffset-30"></div>
                                        <button
                                            v-if="shop && shop.is_open"
                                            class="bt-orange add-to-cart-btn"
                                            v-on:click="addToCart"
                                        >
                                            Kosárba
                                        </button>
                                        <b style="color: #E8BB86" v-else
                                            >Az üzlet jelenleg zárva!</b
                                        >
                                        <div class="voffset-30"></div>
                                        <router-link
                                            :to="{ name: 'Menu' }"
                                            style="color: #E8BB86"
                                            >Vissza az étlapra</router-link
                                        >
                                        <div class="voffset-10"></div>
                                        <p>
                                            Áraink a környezettudatos csomagolás
                                            díját tartalmazzák.
                                        </p>
                                        <div class="voffset-10"></div>
                                        <small
                                            >Allergének:
                                            <span
                                                v-for="(item,
                                                index) in product.allergens"
                                                v-bind:key="'allergen-' + index"
                                                ><span v-if="index !== 0"
                                                    >,&nbsp;</span
                                                >{{ item.name }}</span
                                            >
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="voffset-131"></div>
            <div class="container" style="color: white;">
                <div class="row">
                    <div class="col-12" v-html="product.content"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Product",
    props: ["shop"],
    data() {
        return {
            product: {
                additionals: [],
                packages: []
            },
            model: {
                product_id: null,
                quantity: 1,
                packages: [],
                extras: {},
                comment: null
            }
        };
    },
    beforeMount() {
        this.getProduct();
    },
    computed: {
        calculatedPrice: function() {
            let summary = 0;
            for (const it in this.model.extras) {
                for (const xit in this.product.additionals) {
                    if (
                        parseInt(this.product.additionals[xit].id) ===
                            parseInt(it) &&
                        this.model.extras[it]
                    ) {
                        summary += parseInt(
                            this.product.additionals[xit].price_n
                        );
                    }
                }
            }

            for (const it in this.model.packages) {
                if (this.model.packages[it] !== null) {
                    for (const xit in this.product.packages) {
                        if (
                            parseInt(this.product.packages[xit].id) ===
                            parseInt(it)
                        ) {
                            for (const kit in this.product.packages[xit]
                                .products) {
                                if (
                                    parseInt(
                                        this.product.packages[xit].products[kit]
                                            .id
                                    ) === parseInt(this.model.packages[it])
                                ) {
                                    summary += parseInt(
                                        this.product.packages[xit].products[kit]
                                            .price_n
                                    );
                                }
                            }
                        }
                    }
                }
            }

            summary += parseInt(this.product.price);

            summary *= this.model.quantity;

            if (isNaN(summary)) {
                return 0;
            }
            return summary;
        }
    },
    watch: {
        $route: {
            handler: function() {
                this.getProduct();
            },
            deep: true
        }
    },
    methods: {
        addToCart() {
            if (
                this.product.additionals.length > 0 &&
                (!this.model.extras ||
                    Object.keys(this.model.extras).length < 1 ||
                    this.model.extras[-1] === false)
            ) {
                //alert("DANGER");
                //return false;
            }

            let err = false;
            if (this.product.packages.length > 0) {
                for (const it in this.model.packages) {
                    if (this.model.packages[it] === null) {
                        err = true;
                    }
                }
            }

            if (err) {
                //alert("DANGER2");
                //return false;
            }

            const self = this;
            const cartUuid = localStorage.getItem("cart_uuid");
            this.$http
                .post(`${this.url}/cart/add/${cartUuid}`, this.model)
                .then(() => {
                    self.model = {
                        product_id: self.product.id,
                        quantity: 1,
                        packages: {},
                        extras: {},
                        comment: null
                    };
                    for (const it in self.product.packages) {
                        self.model.packages[
                            self.product.packages[it].id
                        ] = null;
                    }
                })
                .finally(() => {
                    this.$eventHub.$emit("updateCart", true);
                });
        },
        getProduct() {
            this.$http
                .get(`${this.url}/product/get/${this.$route.params.slug}`)
                .then(response => {
                    this.product = response.data.data;
                    this.model.product_id = this.product.id;
                    for (const it in this.product.packages) {
                        this.model.packages[
                            this.product.packages[it].id
                        ] = null;
                    }
                });
        }
    }
};
</script>

<style>
small,
li {
    color: #fff;
}

.allergen_ul li {
    list-style: none;
}

.white {
    color: #fff;
}

.price-formatted {
    color: #e8bb86;
    font-size: x-large;
}

.add-to-cart-btn {
    float: none !important;
    border: solid 1px #e8bb86 !important;
    background-color: #e8bb86 !important;
}

.product-image {
    /*height: 45% !important;*/
}

@media only screen and (max-width: 600px) {
    .product-image {
        /*height: 30% !important;*/
        min-height: 380px !important;
    }
}

.booking-form label {
    font-size: 14px;
}
.booking-form textarea,
.booking-form textarea:hover,
.booking-form textarea:focus,
.booking-form textarea:active {
    border: 1px solid #fff;
    color: #fff !important;
}
</style>
